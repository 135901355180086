<div class="page-title">
    <span>
        get in touch
    </span>
</div>

<div class="container h-100 text-center">
    <br>

    <p>
        If you'd like to know more about Renato and his work, business and media inquiries, commissions or just say hello, please send a message to the e-mail below.
    </p>

    <a class="btn btn-lg" target="_blank" href="mailto:contact@rodriguesrenato.com">
        contact@rodriguesrenato.com
    </a>

    <div class="mt-5">
        <h3>Find me online:</h3>
        <app-social></app-social>
    </div>


</div>