import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  paintings: any[];

  constructor() { 
    this.paintings= [];
  }

  ngOnInit(): void {
    this.paintings= [
      {title: '"GRAYSTONE" (UNAVALIABLE)', cover: '../../../../assets/img/paintings/greystone.JPG'},
      {title: '"THE LIGHT IN THE DARK" (UNAVALIABLE)', cover: '../../../../assets/img/paintings/thedark.JPG'},
      {title: '"PRIDE" (UNAVALIABLE)', cover: '../../../../assets/img/paintings/preview.jpg'},
      {title: '"A BLUE PLANET" (UNAVALIABLE)', cover: '../../../../assets/img/paintings/ablueplanet.jpeg'},
      {title: '"A"', cover: '../../../../assets/img/paintings/a.jpg'},
      {title: '"B1" (UNAVALIABLE)', cover: '../../../../assets/img/paintings/b1.JPG'},
      {title: '"IT\'s RAINING IN HELL" (UNAVALIABLE)', cover: '../../../../assets/img/paintings/itsraininginhell.JPG'},
      {title: '"THE DARK SIDE OF THE MOON" (UNAVALIABLE)', cover: '../../../../assets/img/paintings/thedarksideofthemoon.jpeg'},
      {title: '"THE GARDEN" (UNAVALIABLE)', cover: '../../../../assets/img/paintings/thegarden.JPG'},
      {title: '"B2"', cover: '../../../../assets/img/paintings/b2.JPG'},
    ];
  }

}
