import { Component, OnInit } from '@angular/core';
import { PropertiesService } from 'src/app/services/properties.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  fullName: string='';

  constructor() { }

  ngOnInit(): void {
    this.fullName= PropertiesService.fullName;
  }

}
