import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GalleryComponent } from './views/portifolio/gallery/gallery.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PortifolioComponent } from './views/portifolio/portifolio.component';
import { AboutComponent } from './views/portifolio/about/about.component';
import { ContactComponent } from './views/portifolio/contact/contact.component';
import { SocialComponent } from './views/social/social.component';
import { LoaderComponent } from './views/portifolio/loader/loader.component';
import { HomeComponent } from './views/portifolio/home/home.component';
import { ProductComponent } from './views/portifolio/product/product.component';

@NgModule({
  declarations: [
    AppComponent,
    GalleryComponent,
    PortifolioComponent,
    AboutComponent,
    HomeComponent,
    ContactComponent,
    SocialComponent,
    LoaderComponent,
    ProductComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
