<div class="page-title mt-5">
    <span>
        original artwork
    </span>
</div>

<div class="container">

    <div class="gallery">
        <div *ngFor="let item of paintings; index as i;" class="gallery-item" style="text-align: center; cursor: pointer;">
            <a>                
                <img style="border-radius: 6px;" src="{{item.cover}}" class="w-100 shadow-1-strong" alt="{{item.title}}" />
            </a>
            <span>{{item.title | titlecase}}</span>
        </div>
    </div>

</div>