import { animate, state, style, transition, trigger } from '@angular/animations';
import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PropertiesService } from 'src/app/services/properties.service';

@Component({
  selector: 'app-portifolio',
  templateUrl: './portifolio.component.html',
  styleUrls: ['./portifolio.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition('void => *', animate(2000)),
      transition('* => void', animate(2000))
    ])
  ]
})
export class PortifolioComponent implements OnInit {

  currentBody: string = '';
  previousBody: string = '';
  fullName: string = '';
  loading: boolean = false;

  constructor(private location: LocationStrategy) {
    // preventing back button in browser implemented by "Samba Siva"  
    history.pushState(null, 'null', window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, 'null', window.location.href);
      let pageTemp: string = this.currentBody;
      this.currentBody = this.previousBody;
      this.previousBody = pageTemp;
    });
  }

  ngOnInit(): void {
    this.currentBody = 'artwork';
    this.fullName = PropertiesService.fullName;
  }

  async changeBody(event?: any) {
    if (this.currentBody != event.target.name) {

      this.loading = true;
      await new Promise(resolve => setTimeout(resolve, 500)); // 3 sec

      if (event) {
        this.previousBody = this.currentBody;
        this.currentBody = event.target.name;
      }
      else {
        this.previousBody = this.currentBody;
        this.currentBody = 'artwork';
      }
      this.loading = false;


    }
  }


}
