<div class="social">
    <a class="btn btn-lg" target="_blank" href="https://instagram.com/renato.rodrigs">
        <em class="bi bi-instagram"></em>
    </a>
    <a class="btn btn-lg" target="_blank" href="https://www.facebook.com/Renato-Rodrigues-art-106511678040202">
        <em class="bi bi-facebook"></em>
    </a>
    <a class="btn btn-lg" target="_blank" href="mailto:contact@rodriguesrenato.com">
        <em class="bi bi-envelope"></em>
    </a>

</div>