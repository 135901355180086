<div class="page-title">
    <span>
        about
    </span>
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-6" style="text-align: center;">
            <img style="border-radius: 50%; width: 40vw; max-width: 260px;" src="./../../../../assets/img/profile.jpg"
                alt="profile pictrue">
        </div>
        <div class="col-sm-6" style="display: flex; min-height: 120px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; text-align: justify;">
            <p>
                <strong>Renato Rodrigues</strong> is a Brazilian contemporary absctract artist specialized in acrylic pouring technique.
            </p>
        </div>
    </div>

</div>