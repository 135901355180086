<div class="container-fluid top">
    <div name="header" id="header">
        <span class="display-5" name="artwork" (click)="changeBody()">{{fullName | uppercase}}</span>
        <button class="btn btn-lg" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight">
            <em class="bi bi-list"></em>
        </button>
    </div>
</div>

<div class="content">
    <app-loader *ngIf="loading"></app-loader>
    <app-home *ngIf="currentBody == 'artwork'"></app-home>
    <app-about *ngIf="currentBody == 'about'"></app-about>
    <app-contact *ngIf="currentBody == 'contact'"></app-contact>
    <app-product *ngIf="currentBody == 'product'"></app-product>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="container-fluid">
        <div id="offcanvas-body">
            <div style="text-align: end;">
                <button type="button" class="btn btn-lg" data-bs-dismiss="offcanvas" aria-label="Close">
                    <em class="bi bi-x" style=" font-size: 2.25rem; align-self: flex-end;"></em>
                </button>
            </div>

            <div id="tabs">
                <button data-bs-dismiss="offcanvas" type="button" name="artwork" class="btn"
                    [ngClass]="{selected: currentBody == 'artwork'}" (click)="changeBody($event)">ORIGINAL
                    ARTWORK</button>
                <button data-bs-dismiss="offcanvas" type="button" name="about" class="btn"
                    [ngClass]="{selected: currentBody == 'about'}" (click)="changeBody($event)">ABOUT</button>
                <button data-bs-dismiss="offcanvas" type="button" name="contact" class="btn"
                    [ngClass]="{selected: currentBody == 'contact'}" (click)="changeBody($event)">CONTACT</button>
                <!-- <button data-bs-dismiss="offcanvas" type="button" name="contact" class="btn"
                    [ngClass]="{selected: currentBody == 'contact'}" (click)="changeBody($event)">BLOG</button> -->

            </div>
            <div>
                <app-social class="mt-5 pt-5"></app-social>
            </div>
        </div>
    </div>
</div>
<div id="footer" style="border-top: 1px solid darkgray;">
    <div class="container-fluid">
        <div class="footer-top">
            <img src="../../../assets/img/signature.png" alt="">
            <app-social></app-social>
        </div>
    </div>
    <div class="bg-light">
        <span class="m-3">&copy; 2021 - Renato Rodrigues | all rights reserved.</span>
    </div>
</div>